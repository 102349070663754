import { useEffect, useRef, useState, useCallback } from "react";
import Parchment from "parchment";

import "react-quill/dist/quill.snow.css";
import { uploadFile } from "@/services/file.service";

type Props = {
  name: string;
  required?: boolean;
  errors: { [key: string]: any };
  touched: { [key: string]: any };
  className: string;
  values: any;
  setFieldValue: any;
};
export const RichTextEditorField = ({
  name,
  required,
  className,
  values,
  errors,
  setFieldValue,
}: Props) => {
  const imageHandler = async () => {
    const input: any = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      // file type is only image.
      if (/^image\/\w*/i.test(file.type)) {
        const url = await uploadFile(file);
        var selection = editorRef.current?.getEditor()?.getSelection(true);
        editorRef.current
          ?.getEditor()
          .insertEmbed(selection?.index, "image", url);
      }
    };
  };

  const [modules, setModules] = useState<{ [key: string]: any }>({
    toolbar: {
      container: [
        [{ size: [] }],
        [{ align: ["", "right", "center", "justify"] }],
        [{ color: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "bullet" }, { list: "ordered" }],
        ["link", "image", "code-block", "blockquote"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
    clipboard: { matchVisual: false },
  });

  const saveContent = useCallback(() => {
    if (editorRef.current && editorRef.current.getEditor()) {
      const html = editorRef.current.getEditor().root.innerHTML;
      valueRef.current = html;
      setFieldValue(name, html);
    }
  }, [setFieldValue, name]);

  const setupImageListeners = useCallback(() => {
    if (!editorRef.current) return;

    const editorElement = editorRef.current.getEditor().root;
    const images = editorElement.querySelectorAll("img");

    images.forEach((img: HTMLElement) => {
      if (!imagesProcessed.current.has(img)) {
        img.addEventListener("mouseup", () => setTimeout(saveContent, 50));
        img.addEventListener("resize", () => setTimeout(saveContent, 50));

        const observer = new MutationObserver(() => saveContent());
        observer.observe(img, { attributes: true, attributeFilter: ["style", "width", "height"] });

        imagesProcessed.current.add(img);
      }
    });
  }, [saveContent]);

  useEffect(() => {
    if (typeof window !== undefined) {
      (async () => {
        const { Quill } = await import("react-quill");
        const ImageResize = await import("quill-image-resize-module");
        Quill?.register("modules/imageResize", ImageResize);
        Quill?.register(
          new Parchment.Attributor.Style("display", "display", {
            whitelist: ["inline"],
          })
        );
        Quill?.register(
          new Parchment.Attributor.Style("float", "float", {
            whitelist: ["left", "right", "center"],
          })
        );
        Quill?.register(new Parchment.Attributor.Style("margin", "margin", {}));
        setModules((modules) => ({
          ...modules,
          imageResize: {
            parchment: Parchment,
          },
        }));
      })();
    }
  }, []);

  const ReactQuill =
    typeof window !== undefined ? require("react-quill") : null;
  const contentElement = useRef<any>(null);
  const editorRef = useRef<any>(null);
  const imagesProcessed = useRef<Set<HTMLElement>>(new Set());

  useEffect(() => {
    const qlEditorElement = document.querySelector(".ql-editor");
    contentElement.current = qlEditorElement;
  }, []);

  const value = values?.[name] || "";
  const valueRef = useRef(undefined);

  useEffect(() => {
    if (modules.imageResize && value !== valueRef.current) {
      editorRef.current.editor.root.innerHTML = value;
      valueRef.current = value;
    }
  }, [value, modules.imageResize]);

  useEffect(() => {
    const qlEditorElement = document.querySelector(".ql-editor");
    contentElement.current = qlEditorElement;
  }, []);

  return (
    <div className={className}>
      {ReactQuill && modules.imageResize && (
        <ReactQuill
          theme="snow"
          modules={modules}
          ref={editorRef}
          className={[
            "h-full flex flex-col",
            required && errors[name] ? "ant-input-status-error" : "",
          ].join(" ")}
          onChange={(value) => {
            valueRef.current = value;
            setFieldValue(name, value);
            setupImageListeners();
          }}
        />
      )}
      {required && errors[name] && (
        <div className="text-[#F21D1D] text-xs mt-2">{errors[name]}</div>
      )}
    </div>
  );
};
